@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('fonts/Inter-roman.var.woff2?v=3.19') format('woff2');
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('fonts/Inter-italic.var.woff2?v=3.19') format('woff2');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: var(--p-xspot-font-secondary);
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

.text-error {
  color: var(--p-xspot-error-color);
}

.text-warning {
  color: var(--p-xspot-warning-color);
}

.text-success {
  color: var(--p-xspot-success-color);
}

.text-highlight {
  color: var(--p-highlight-color);
}

.text-inter {
  font-family: var(--p-xspot-font-family);
}

.text-oswald {
  font-family: var(--p-xspot-font-secondary);
}

.text-info {
  color: var(--p-xspot-info-color);
}

.text-transform-none {
  text-transform: none;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;

  &--secondary {
    color: var(--p-surface-700);
  }
}

.text-wrap-anywhere {
  overflow-wrap: anywhere;
}

.text-flyspot-primary {
  color: $flyspot-primary-color;
}

.text-flyspot-primary-dark {
  color: $flyspot-primary-dark-color;
}

.text-deepspot-primary {
  color: $deepspot-primary-color;
}

.text-deepspot-primary-dark {
  color: $deepspot-primary-dark-color;
}
