.p-floatlabel input:focus ~ label,
.p-floatlabel input.p-filled ~ label,
.p-floatlabel textarea:focus ~ label,
.p-floatlabel textarea.p-filled ~ label,
.p-floatlabel .p-inputwrapper-focus ~ label,
.p-floatlabel .p-inputwrapper-filled ~ label {
  top: 0.625rem;
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.p-inputtext {
  padding: 1.5rem 1rem 0.575rem;
}

.p-floatlabel .p-select .p-select-label {
  padding: 1.5rem 1rem 0.575rem;
}

.p-select-label {
  line-height: 17px;
}

.p-multiselect {
  width: 100%;
}

.p-disabled,
.p-component:disabled {
  opacity: var(--p-disabled-opacity);
}

.p-checkbox-box {
  border-width: 2px;
}

.drop-menu.p-menu
  .p-menuitem:not(.p-highlight):not(.p-disabled):not(:hover).p-focus
  > .p-menuitem-content {
  color: inherit !important;
  background: transparent !important;
}

.p-toast-message {
  border-radius: 4px !important;

  &-icon {
    display: none !important;
  }

  &-text {
    display: flex !important;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;

    .p-toast-detail {
      display: none;
    }
  }
}

p-checkbox:not(:has(.p-checkbox-disabled)) + label,
p-radiobutton:not(:has(.p-radiobutton-disabled)) + label {
  cursor: pointer;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: var(--p-surface-600);
  font-size: 14px;
  font-family: var(--p-xspot-font-family);
}

.p-editor-container .p-editor-toolbar {
  background: transparent;
}

input {
  line-height: 1;
}

p-button button {
  text-transform: uppercase;
  font-family: var(--p-xspot-font-secondary);
  font-weight: 600;
  line-height: 16px;
  padding: 15px 16px;

  &:not(.p-button-outlined) {
    border: 0;
    padding: 16px;
  }

  &.p-button-icon-only {
    gap: 0;
  }
}

.p-multiselect.no-label .p-multiselect-label {
  padding: 0.75rem 1rem 0.575rem;
}

p-multiselect
  .p-multiselect-panel.no-checkbox
  .p-multiselect-items
  .p-multiselect-item
  .p-checkbox {
  display: none;
}

.p-dialog {
  min-width: 0;

  .p-dialog-title {
    font-size: 1.75rem;
    text-transform: uppercase;
    font-family: var(--p-xspot-font-secondary);
  }

  .p-dialog-footer {
    border-top: 1px solid var(--p-surface-100);
    padding: 1rem 1.5rem;
  }

  &.p-confirmdialog {
    min-width: 400px;

    .p-dialog-title {
      text-transform: none;
      font-size: 1.5rem;
    }

    .p-dialog-footer {
      border-top: 0;

      .p-button {
        min-width: 6rem;
      }

      .p-confirmdialog-reject-button {
        background: transparent;
        color: var(--p-primary-color);

        &:hover {
          background: var(--p-xspot-primary-hover-color);
        }
      }
    }
  }
}

:root {
  --p-floatlabel-font-weight: 400;
}

.surface-hover {
  background-color: var(--p-surface-hover) !important;
}

.surface-ground {
  background-color: var(--p-surface-ground) !important;
}

.surface-border {
  border-color: var(--p-surface-border) !important;
}
