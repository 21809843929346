.content-header-button {
  & .p-button {
    border: 0.1rem solid var(--p-surface-400);
    justify-content: start;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      border-color: inherit;
    }
  }

  &.is-item-selected .p-button {
    border-color: var(--p-primary-color) !important;
  }

  &--selected .p-button {
    border-color: var(--p-primary-color);
  }

  & span {
    color: var(--p-text-color);
    font-family: var(--p-xspot-font-family);
    text-align: left;
    text-transform: none;
  }
}
