h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

.text-error {
  color: var(--error-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-success {
  color: var(--success-color);
}

.text-highlight {
  color: var(--highlight-text-color);
}

.text-inter {
  font-family: var(--font-family);
}

.text-oswald {
  font-family: var(--font-secondary);
}

.text-info {
  color: var(--info-color);
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;

  &--secondary {
    color: var(--surface-700);
  }
}

.text-wrap-anywhere {
  overflow-wrap: anywhere;
}

.text-flyspot-primary {
  color: $flyspot-primary-color;
}

.text-flyspot-primary-dark {
  color: $flyspot-primary-dark-color;
}

.text-deepspot-primary {
  color: $deepspot-primary-color;
}

.text-deepspot-primary-dark {
  color: $deepspot-primary-dark-color;
}
