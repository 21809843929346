.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.p-float-label label {
  margin-top: -0.4rem;
}

.p-float-label > p-dropdown + label {
  margin-top: -0.3rem;
}

.p-float-label .p-dropdown .p-dropdown-label {
  padding: 1.55rem 1rem 0.525rem;
}

.drop-menu.p-menu
  .p-menuitem:not(.p-highlight):not(.p-disabled):not(:hover).p-focus
  > .p-menuitem-content {
  color: inherit !important;
  background: transparent !important;
}

.p-dropdown-label {
  line-height: 17px;
}

.p-toast-message {
  border-radius: 4px !important;

  &-icon {
    display: none !important;
  }

  &-text {
    display: flex !important;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;

    .p-toast-detail {
      display: none;
    }
  }
}

p-checkbox:not(:has(.p-checkbox-disabled)) + label,
p-radiobutton:not(:has(.p-radiobutton-disabled)) + label {
  cursor: pointer;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: var(--surface-600);
  font-size: 14px;
  font-family: var(--font-family);
}

.p-editor-container .p-editor-toolbar {
  background: transparent;
}

input {
  line-height: 1;
}

p-button button {
  text-transform: uppercase;
  font-family: var(--font-secondary);
  font-weight: 600;
  line-height: 16px;
  padding: 15px 16px;
  display: flex;
  gap: 1rem;

  &:not(.p-button-outlined) {
    border: 0;
    padding: 16px;
  }

  &.p-button-icon-only {
    gap: 0;
  }
}

.p-multiselect.no-label .p-multiselect-label {
  padding: 0.75rem 1rem 0.575rem;
}

p-multiselect
  .p-multiselect-panel.no-checkbox
  .p-multiselect-items
  .p-multiselect-item
  .p-checkbox {
  display: none;
}

.p-dialog {
  min-width: 0;
}
