@import './mixins';

.clock-circle-broken-icon {
  @include icon('../../assets/icons/clock-circle-broken.svg', 32px, 32px);
}

.wallet-outline-icon {
  @include icon('../../assets/icons/wallet-outline-icon.svg', 21px, 21px);
}

.clock-circle-outline-icon {
  @include icon('../../assets/icons/clock-circle-outline-icon.svg', 21px, 21px);
}

.payment-delay-icon {
  @include icon('../../assets/icons/payment-delay.svg', 21px, 21px);
}

.bag-icon {
  @include icon('../../assets/icons/solar-bag.svg', 21px, 21px);
}

.tag-price-icon {
  @include icon('../../assets/icons/solar-tag-price.svg', 21px, 21px);
}

.pencil-icon {
  @include icon('../../assets/icons/solar-pencil.svg', 21px, 21px);
}

.trash-icon {
  @include icon('../../assets/icons/solar-trash.svg', 21px, 21px);
}

.user-icon {
  @include icon('../../assets/icons/solar-user.svg', 21px, 21px);
}

.outlook-icon {
  @include icon('../../assets/icons/outlook.svg', 21px, 21px);
}

.voucher-outline-icon {
  @include icon('../../assets/icons/voucher-outline.svg', 21px, 21px);
}

.ticket-icon {
  @include icon('../../assets/icons/solar-ticket.svg', 21px, 21px);
}

.cart-plus-icon {
  @include icon('../../assets/icons/solar-cart-plus-linear.svg', 21px, 21px);
}

.scuba-icon {
  @include icon('../../assets/icons/scuba-diving.svg', 14px, 14px);
}

.freediving-icon {
  @include icon('../../assets/icons/freediving.svg', 14px, 14px);
}

.mermaiding-icon {
  @include icon('../../assets/icons/mermaiding.svg', 14px, 14px);
}
