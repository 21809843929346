@keyframes shine-animation {
  0% {
    left: -100px;
  }
  40% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes highlight-animation {
  0% {
    background-color: var(--p-surface-hover);
  }
  20% {
    background-color: var(--p-surface-300);
  }
  100% {
    background-color: var(--p-surface-hover);
  }
}
