.status-badge {
  display: block;
  padding: 0.5rem;
  border-radius: 1rem;
  font-weight: bold;
  margin: -0.25rem auto;
  width: min-content;
  font-size: 0.875rem;
  text-wrap: nowrap;

  &--green {
    color: var(--p-surface-0);
    background-color: var(--p-green-500);
  }

  &--blue {
    color: var(--p-surface-0);
    background-color: var(--p-blue-500);
  }

  &--gray {
    color: var(--p-surface-600);
    background-color: var(--p-surface-300);
  }

  &--yellow {
    color: var(--p-surface-0);
    background-color: var(--p-amber-500);
  }
}
