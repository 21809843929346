@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex';

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 14px;
}

body {
  color: var(--text-color);
  background-color: var(--surface-ground);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.label-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -webkit-fill-available;
  overflow: hidden;
  padding-right: 4px;
}

// wysywig
.ql-size-huge {
  font-size: 2rem;
}

.ql-size-large {
  font-size: 1.5rem;
}

.ql-size-small {
  font-size: 0.875rem;
}

.ql-font-serif {
  font-family:
    Georgia,
    Times New Roman,
    serif;
}

.ql-font-monospace {
  font-family:
    Monaco,
    Courier New,
    monospace;
}

.mobile {
  display: none;
  @media screen and (max-width: $lg) {
    display: block;
  }
}

.desktop {
  @media screen and (max-width: $lg) {
    display: none;
  }
}

.bg-none {
  background: none;
}

.w-fit-content {
  width: fit-content;
}

// input autofill issue https://github.com/primefaces/primeng/issues/15544
input:-webkit-autofill + label,
p-password:has(input:-webkit-autofill) + label {
  top: 1rem;
  font-size: 12px;
}
