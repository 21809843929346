/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'normalize.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import 'base';
@import 'typography';
@import 'forms';
@import 'overrides';
@import 'icons';
@import 'animations';
