.form-control {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  & small {
    display: block;
    font-size: 12px;
  }
}
